import React from 'react'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'

export function Modal({ isOpen, onClose, title, children }) {
  return (
    <Dialog isOpen={isOpen} className="modal">
      <div>
        <span className="pm-close" onClick={onClose}>
          <span className="pm-icon" />
        </span>
        <div className="pm-title">{title}</div>
      </div>
      <div className="pm-content">{children}</div>
    </Dialog>
  )
}
